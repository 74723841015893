.cont_header_tarifs {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
    margin-bottom: 50px;
}

.main_header_tarifs {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px; 
}

.span_header_tarifs {
    font-weight: 100;
    font-size: 16px;
}

.tarif_information_header {
   text-align: center;
   margin-left: 10%;
   margin-right: 10%;
   color: #1a9e3b;
   font-family: "Russo One", sans-serif;
}

.documents_tarifs_cont {
    margin: 50px 10% 10px 10%;
    padding: 50px;
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
    color: #666666;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.documents_tarifs_cont:hover {
    border-left: 7px #efe74a solid;
}

.documents_tarifs_cont h3 {
    color: #1a9e3b;
    font-family: "Russo One", sans-serif;
    text-transform: uppercase;
}

.documents_tarifs_cont p {
    font-family: "Roboto", sans-serif;
    color: #666666;
    margin-top: 10px;

}

.tarifs_btn {
    display: inline-block;
    position: relative;
    background-color: transparent;
    color: #1a9e3b;
    border-radius: 15px;
    padding: 15px;
    text-transform: uppercase;
    border: 3px solid #1a9e3b;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    text-decoration: none;
    font-size: 14px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    width: 200px;
    margin-left: 200px;
}

.documents_tarifs_list {
    text-align: center;
    padding: 10px;
    font-family: "Roboto", sans-serif; 
    margin-bottom: 50px;
}

.documents_tarifs_list p {
    margin: 5px;
}

.documents_tarifs_list p:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #86c7f1;
}

.header_table_tarifs {
    color: #1a9e3b;
    font-family: "Russo One", sans-serif;
    text-align: center;
    margin: 50px;
}

.normativ {
    font-family: "Roboto", sans-serif; 
    color: #666666;
}


/* таблица 1-2 */

.tarifs_data_cont {
    margin-left: 10%;
    margin-right: 10%;
    
}
.tarifs_data_box_headers {
    display: grid;
    grid-template-columns: 1fr 4fr 3fr 2fr; 
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
}
.tarifs_data_box {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 4fr 5fr;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
}

.service_tarifs {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    
}

.period_tarifs {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* таблица 3-4 */

.tarifs_data_cont_energy {
    margin-left: 10%;
    margin-right: 10%;
}
.tarifs_data_box_energy_headers {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 2fr;
    padding: 10px;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif; 
    cursor: pointer;  
}

.tarifs_data_box_energy {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 1fr 1fr;
    padding: 10px;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
}


/* таблица 5 */

.tarifs_data_cont_gaz {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 100px;
}

.tarifs_data_box_gaz {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 2fr;
    padding: 10px;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .tarifs_data_box_headers {
        grid-template-columns: 1fr 4fr 3fr 3fr; 

    }
    .tarifs_data_box {
        grid-template-columns: 1fr 4fr 9fr;
    }

    .tarifs_data_box_energy_headers {
        grid-template-columns: 1fr 5fr 2fr 3fr;
    }
    
    .tarifs_data_box_energy {
        grid-template-columns: 1fr 5fr 2fr 1.5fr 1.5fr;
    }
}

@media screen and (max-width: 1024px) {
    .cont_header_tarifs {
        background-size: 35%;
        }
  }

@media screen and (max-width: 912px) {
    .documents_tarifs_cont {
        padding: 10px;
    }
    .tarifs_btn {
        padding: 10px;
        margin-left: 100px;
    }
}

@media screen and (max-width: 855px) {
    .cont_header_tarifs {
        background-size: 40%;
        }
}

@media screen and (max-width: 560px) {
    .cont_header_tarifs {
    background-size: 45%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    padding: 60px;
    }

    .main_header_tarifs {
        margin-left: 0;
        font-size: 20px;
    }

    .span_header_tarifs {
        font-size: 14px;
    }
  }

@media screen and (max-width: 540px) {
    .documents_tarifs_cont {
        flex-direction: column;
    }

    .tarifs_btn {
        margin-left: 0;
        margin-top: 10px;
    }
    .tarifs_data_cont {
        margin-left: 0;
        margin-right: 0;
        width: 100%; 
        overflow-x: auto; 
        white-space: nowrap;    
    }

    .tarifs_data_box_headers {
        grid-template-columns: 60px 300px 200px 300px; 
        width: 860px;
    }

    .tarifs_data_box_headers h3 {
        white-space: normal;
    }

    .tarifs_data_box {
        grid-template-columns: 60px 300px 500px; 
        width: 860px;
        padding: 0;
    }
    .tarifs_data_box p,
    .period_tarifs p {
        white-space: normal;
    }

    .period_tarifs {
        grid-template-columns: 150px 150px;
        width: 500px;
    }

    .service_tarifs {
        grid-template-columns: 200px 150px 150px;  
    }

    /* таблица 3-4 */

.tarifs_data_cont_energy {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%; 
    overflow-x: auto; 
    white-space: nowrap;   
}
.tarifs_data_box_energy_headers {
    grid-template-columns: 60px 300px 200px 300px;
    width: 860px;
    padding: 0;
}
.tarifs_data_box_energy_headers h3{
   white-space: normal;
}
.tarifs_data_box_energy {
    grid-template-columns: 60px 300px 200px 150px 150px;
    width: 860px;
    padding: 0;
}

.tarifs_data_box_energy p {
    white-space: normal;
}


/* таблица 5 */

.tarifs_data_cont_gaz {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%; 
    overflow-x: auto; 
    white-space: nowrap;  
}

.tarifs_data_box_gaz {
    grid-template-columns: 60px 300px 200px 300px;
    width: 860px;
    padding: 0;
}
.tarifs_data_box_gaz h3, 
.tarifs_data_box_gaz p {
    white-space: normal;
}
}


@media screen and (max-width: 280px) {
.tarif_information_header {
    font-size: 18px;
}
.header_table_tarifs {
    font-size: 14px;
}       
.cont_header_tarifs {
     background-size: 55%;
     clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
     padding: 40px;
    }
    }

    





